<template>
  <div class="pageSize">
    <Head text="健康管理方案" bgColor="#5aaca9"></Head>
    <div class="analysisReport_box11">
      <div class="analysisReport_box11_title">
        <!-- <div class="analysisReport_box11_title_num">01</div> -->
        <div class="analysisReport_box11_title_text">
          健康管理建议
        </div>
      </div>
      <div style="font-size: 30px;color: #475057;margin-top: 40px;">
        1、健康管理方向和阶段建议
      </div>
      <div class="analysisReport_box11_S6_ul">
        <div v-for="(item,index) in stageHealthyManageList" :key="index" class="analysisReport_box11_S6_li">
          <div class="analysisReport_box11_S6_li_A a_flex" style="position: relative;">
            <img class="wh100" style="position: absolute;top: -3px;" src="https://image.giantgocloud.com/www/ImageMapping/image/20240930/EC3F42F2A5B046FBAFB9997C2D0E4BD8.png" alt="" />
            <div style="position: relative;z-index: 1;color: #fff;top: -3px;">
              {{item.title}}
            </div>
          </div>
          <div v-for="(item1,index1) in item.troubleList" :key="index1"
            :style="'border:1px solid '+item1.projectColorCode" class="analysisReport_box11_S6_li_B">
            <div class="analysisReport_box11_S6_li_B_d"></div>
            {{item1.projectName}}
          </div>
        </div>
      </div>
      <div style="font-size: 30px;color: #475057;margin-top: 40px;">
        2、健康管理方向和阶段建议
      </div>
      <div style="display: flex;justify-content: space-between;">
        <div v-for="(item,index) in objData" :key="index" class="analysisReport_box11_content"
          :style="objData.length==1?'width:100%;':'width:47%;'">
          <div class="analysisReport_box11_content_title">
            <div style="font-size: 90%;">
              {{item.foodName}}
            </div>
            <div class="a_flex"
              style="width: 30px;height: 30px;border-radius: 50%;color: #fff;background-color: #5aaca9;margin-left: 30px;">
              {{index+1}}
            </div>
          </div>
          <div class="analysisReport_box11_content_img a_flex" style="overflow: hidden;">
            <img style="width: 100%;" :src="item.foodPicture" alt="" />
          </div>
          <div class="analysisReport_box11_content_ul">
            <div class="analysisReport_box11_content_li">
              <div class="analysisReport_box11_content_li_title">
                材料:
              </div>
              <div class="analysisReport_box11_content_li_text">
                {{item.foodMaterial}}
              </div>
            </div>

            <div class="analysisReport_box11_content_li">
              <div class="analysisReport_box11_content_li_title">
                做法:
              </div>
              <div class="analysisReport_box11_content_li_text">
                <div v-html="item.foodMethod"></div>
              </div>
            </div>
            <div class="analysisReport_box11_content_li">
              <div class="analysisReport_box11_content_li_title">
                注意事项:
              </div>
              <div class="analysisReport_box11_content_li_text">
                {{item.foodAttention}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <Bottom /> -->
  </div>
</template>

<script>
import Head from './head.vue'
import Bottom from './bottom.vue'
export default {
  props: ['objData', 'stageHealthyManageList'],
  components: {
    Head,
    Bottom
  }
}
</script>

<style lang="scss" scoped>
  .analysisReport_box11 {
    width: 974px;
    margin: 0 auto;

    .analysisReport_box11_title {
      display: flex;
      align-items: center;
      margin-top: 65px;

      .analysisReport_box11_title_num {
        font-family: Bahnschrift;
        font-weight: 400;
        font-size: 114px;
        color: #DFE3E8;
      }

      .analysisReport_box11_title_text {
        position: relative;
        top: -15px;
        margin-left: 15px;
        font-weight: 500;
        font-size: 42px;
        color: #5aaca9;

        &::after {
          content: "";
          position: absolute;
          bottom: -24px;
          left: 0px;
          width: 77px;
          height: 2px;
          background: #5aaca9;
          z-index: 1;
        }
      }

    }

    .analysisReport_box11_content {
      margin-top: 20px;

      .analysisReport_box11_content_title {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        padding: 13px 30px 14px 35px;
        border-radius: 0 40px 40px 0;
        background-color: #e0f4f3;
        font-weight: 500;
        font-size: 36px;
        color: #5aaca9;
      }

      .analysisReport_box11_content_img {
        margin-top: 43px;
        width: 100%;
        height: 250px;
      }

      .analysisReport_box11_content_ul {
        margin-top: 66px;

        .analysisReport_box11_content_li {
          margin-bottom: 15px;

          .analysisReport_box11_content_li_title {
            position: relative;
            font-size: 28px;
            color: #5aaca9;

            &::after {
              content: "";
              position: absolute;
              bottom: -10px;
              left: 0px;
              width: 77px;
              height: 3px;
              background: #5aaca9;
              z-index: 1;
            }
          }

          .analysisReport_box11_content_li_text {
            margin-top: 15px;
            font-size: 20px;
          }
        }
      }
    }
  }

  .analysisReport_box11_S6_ul {
    margin-top: 20px;

    .analysisReport_box11_S6_li {
      display: flex;
      align-items: center;
      margin-top: 10px;
        font-size: 20px;
      .analysisReport_box11_S6_li_A {
        display: flex;
        align-items: center;
        width: 200px;
        height: 50px;
        margin-right: 10px;
      }

      .analysisReport_box11_S6_li_B {
        display: flex;
        align-items: center;
        width: 230px;
        height: 59px;
        border-radius: 15px;
        border: 1px solid red;
        margin-right: 10px;
        .analysisReport_box11_S6_li_B_d {
          margin: 0 13px 0 23px;
          width: 7px;
          height: 7px;
          background: #0d1720;
          border-radius: 50%;
        }
      }
    }
  }
</style>
