<template>
    <div class="testReport_pages13 pageSize">

        <Head text="健康管理方案" bgColorRight="#5aaca9" bgColor="#5aaca9"></Head>
        <div class="testReport_pages4_title_box">
            <div class="title">{{ showData.sickName }}概述</div>
            <div class="line"></div>
        </div>
        <div class="testReport_text">
            {{ showData.sickIntroduce }}
        </div>
        <div class="testReport_pages13_content">
            <div class="pages13_main">
                <div class="pages13_list">
                    <div class="pages13_lsit_tilte">
                        病因:
                    </div>
                    <div class="pages13_lsit_test" v-for="(item, index) in showData.sickReason" :key="index">
                        <span class="round"></span>{{ item }}
                    </div>
                </div>
                <div class="pages13_list" style="padding-left: 100px;">
                    <div class="pages13_lsit_tilte">
                        风险因素：
                    </div>
                    <div class="pages13_lsit_test" v-for="(item, index) in showData.riskFactor" :key="index">
                        <span class="round"></span>{{ item }}
                    </div>
                </div>
            </div>
            <div class="pages13_main" style="padding-top: 40px;">
                <div class="pages13_list">
                    <div class="pages13_lsit_tilte">
                        症状:
                    </div>
                    <div class="pages13_lsit_test"  v-for="(item,index)  in showData.symptom" :key="index">
                        <span class="round"></span> {{ item }}
                    </div>
                </div>
                <div class="pages13_list" style="padding-left: 100px;">
                    <div class="pages13_lsit_tilte">
                        并发症:
                    </div>
                    <div class="pages13_lsit_test" v-for="(item,index)  in showData.complication" :key="index">
                        <span class="round"></span>{{ item }}
                    </div>
                </div>
            </div>
            <!-- <div class="testReport_pages13_content_bg">
                <img class="wh100"
                    src="https://image.giantgocloud.com/www/ImageMapping/image/20240430/7D22EF2609C1476A9887E5B0CBDE7FBF.png"
                    alt="" />
            </div>
            <div style="position: relative;">
                <div class="testReport_pages13_content_text">
                    {{showData.sickName}}的风险因素
                </div>
                <div class="testReport_pages13_content_ul">
                    <div class="testReport_pages13_content_li">
                        <div class="pathogeny">
                            <div class="pathogeny_text">
                                病因
                            </div>
                        </div>
                        <div class='testReport_pages13_content_li_title a_c'>
                            病因
                        </div>
                        <div class="testReport_pages13_content_li_li" v-for="(item, index) in showData.sickReason" :key="index">
                            <div class="testReport_pages13_content_li_li_left"></div>
                            <div class="testReport_pages13_content_li_li_text">
                               {{ item }}
                            </div>
                        </div>
                    </div>
                    <div class="testReport_pages13_content_li">
                        <div class="pathogeny">
                            <div class="pathogeny_text">
                                风险因素
                            </div>
                        </div>
                        <div class='testReport_pages13_content_li_title a_c'>
                            风险因素：
                        </div>
                        <div class="testReport_pages13_content_li_li" v-for="(item, index) in showData.riskFactor" :key="index">
                            <div class="testReport_pages13_content_li_li_left"></div>
                            <div class="testReport_pages13_content_li_li_text">
                                {{ item }}
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->

        </div>
    </div>
</template>

<script>
import Head from "./head.vue"
export default {
    props: ['objData'],
    data() {
        return {
            showData: '',
        }
    },
    methods: {

    },
    watch: {
        objData: {
            immediate: true,
            deep: true, // 开启深度监听 专门对付复杂数据类型
            handler: function (newValueA, oldValue) {
                // console.log('newValueA', newValueA)
                this.showData = newValueA
            }
        }
    },
    components: {
        Head,
    }
}
</script>

<style lang="scss" scoped>
.testReport_pages13 {
    .testReport_pages4_title_box {
        padding-left: 103px;
        padding-top: 60px;

        .title {
            font-family: Source Han Sans CN;
            font-weight: 500;
            font-size: 42px;
            color: #5aaca9;
        }

        .line {
            width: 100px;
            height: 3px;
            margin-top: 30px;
            background-color: #5aaca9;
        }
    }

    .testReport_text {
        width: 817px;
        height: 31px;
        margin: 0 auto;
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 17px;
        color: #474F57;
        line-height: 20px;
        padding: 20px 0;
    }

    .testReport_pages13_content {
        overflow: hidden;
        margin-top: 68px;
        position: relative;
        width: 980px;
        height: 1230px;

        .pages13_main {
            display: flex;
            justify-content: space-between;
            margin-left: 120px;
            .pages13_list {
                .pages13_lsit_tilte {
                    width: 278px;
                    height: 50px;
                    line-height: 50px;
                    background: #5aaca9;
                    border-radius: 26px;
                    font-size: 28px;
                    font-weight: 500;
                    color: #FFFFFF;
                    padding-left: 22px;
                    margin-bottom: 20px;
                }
                .pages13_lsit_test{
                    width: 300px;
                    font-size: 18px;
                    padding: 6px 0;
                    padding-left: 20px;
                    color: #474F57;
                    .round{
                        display: inline-block;
                        width: 8px;
                        height: 8px;
                        border-radius: 4px;
                        background-color: #5aaca9;
                       margin-right: 10px;
                    }
                }
            }
        }

        .testReport_pages13_content_bg {
            position: absolute;
            width: 100%;
            height: 100%;
            overflow: hidden;
        }

        .testReport_pages13_content_text {
            margin-left: 186px;
            margin-top: 42px;
            font-size: 42px;
            font-weight: 500;
            color: #5aaca9;
        }

        .testReport_pages13_content_text1 {
            margin-left: 186px;
            margin-top: 90px;
            font-size: 22px;
            font-weight: 400;
            color: #474F57;
        }

        .testReport_pages13_content_ul {
            margin-top: 140px;

            .testReport_pages13_content_li {
                position: relative;
                margin-bottom: 60px;

                .pathogeny {
                    width: 108px;
                    height: 44px;
                    background: url('https://image.giantgocloud.com/www/ImageMapping/image/20240808/F94D0784AB6D44668F2042EA773900B7.png');
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;

                    .pathogeny_text {
                        position: relative;
                        top: -10px;
                        font-family: Source Han Sans CN;
                        font-size: 24px;
                        color: #69B287;
                        padding-left: 50px;
                        margin-bottom: 20px;
                    }
                }



                .testReport_pages13_content_li_title {
                    margin-left: 162px;
                    width: 578px;
                    height: 50px;
                    background: #5aaca9;
                    border-radius: 26px;
                    font-size: 28px;
                    font-weight: 500;
                    color: #FFFFFF;
                    padding-left: 22px;
                }
            }

            .testReport_pages13_content_li_li {
                display: flex;
                align-items: center;
                margin-top: 20px;

                .testReport_pages13_content_li_li_left {
                    margin-right: 60px;
                    margin-left: 102px;
                    width: 22px;
                    height: 22px;
                    background: #FFFFFF;
                    border: 2px solid #5aaca9;
                    border-radius: 50%;
                }

                .testReport_pages13_content_li_li_text {
                    font-size: 22px;
                    font-weight: 400;
                    color: #474F57;
                }
            }
        }
    }
}
</style>
