<template>
    <div>
        <div v-for="(item, index) in objData.singleAnalysisInModel?.productModel?.customProductList">
            <template v-if="item.productsList.length >= 5 && item.productsList.length <= 8">
                <!-- 第一段 -->
                <div class="pageSize main">

                    <Head text="健康管理方案" bgColor="#2baea9"></Head>
                    <div style="padding-left: 104px;">
                        <div class="title">
                            <div class="title_left">0{{ productIndex+index }}</div>
                            <div class="title_right">
                                营养建议
                                <div class="line"></div>
                                <!-- <div class="number">{{ index + 1 }}</div> -->
                            </div>
                        </div>
                        <div class="text">
                            {{ objData.singleAnalysisInModel?.productModel.adviceText }}
                        </div>
                        <div class="food">
                            <div class="food_left">
                                <div class="meal">
                                    第
                                    <span v-if="index == 0">一</span>
                                    <span v-if="index == 1">二</span>
                                    <span v-if="index == 2">三</span>
                                    餐:
                                    <div class="line">
                                    </div>
                                </div>
                                <div class="meal_item" v-for="(item1, index1) in item.productsList">
                                    <template v-if="index1 < 4">
<!--                                        <div class="meal_item_title">
                                            <span v-if="item1.unBalanceName">
                                                <span>{{ item1.unBalanceName }}</span>
                                                <span class="line"></span>
                                                <span v-for="(item3, index3) in item1.subUnBalanceNameList"
                                                    style="font-size: 20px;">({{ item3 }})</span>
                                            </span>
                                            <span v-else>{{ item1.sickName }}</span>
                                        </div> -->
                                        <div class="product_name">
                                            {{index1+1}} {{item1.sickName}}——{{ item1.productName }}
                                        </div>
                                        <!-- <div class="product_name_line"></div> -->
                                        <div class="meal_content">
                                            <div class="meal_item_list" v-for="(item2, index2) in item1.effect">
                                                <div class="meal_item_list_round">
                                               <!--     <img src="https://image.giantgocloud.com/www/ImageMapping/image/20240606/13D5196D44294522A8D00F420E2FE084.png"
                                                        alt="" style="width: 100%;height: 100%;"> -->
                                                </div>
                                                <div class="meal_item_list_text">
                                                    {{ item2.effect }}: {{ item2.ingredient }}
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                                <!-- <div class="details">
                                    <div class="details_title">产品使用详情： </div>
                                    <div class="details_list" v-for="(item1, index1) in item.productDetail">
                                        {{ index1 + 1 }} {{ item1.title }}：{{ item1.view }}
                                    </div>
                                </div> -->
                            </div>
                            <div class="food_right">
                                <img class="wh100"
                                    src="https://image.giantgocloud.com/www/ImageMapping/image/20240929/034C0BCDE53841C7A86B8F254751B03A.png"
                                    alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 第二段 -->
                <div class="pageSize main">

                    <Head text="健康管理方案" bgColor="#C8EBED"></Head>
                    <div style="padding-left: 104px;">
                        <div class="title">
                            <div class="title_left">0{{ productIndex+index }}</div>
                            <div class="title_right">
                                营养建议
                                <div class="line"></div>
                                <!-- <div class="number">{{ index + 1 }}</div> -->
                            </div>
                        </div>
                        <div class="text">
                            {{ objData.singleAnalysisInModel?.productModel.adviceText }}
                        </div>
                        <div class="food">
                            <div class="food_left">
                                <div class="meal">
                                    第
                                    <span v-if="index == 0">一</span>
                                    <span v-if="index == 1">二</span>
                                    <span v-if="index == 2">三</span>
                                    餐:
                                    <div class="line">
                                    </div>
                                </div>
                                <div class="meal_item" v-for="(item1, index1) in item.productsList">
                                    <template v-if="index1 >= 4">
                                   <!--     <div class="meal_item_title">
                                            <span v-if="item1.unBalanceName">
                                                <span>{{ item1.unBalanceName }}</span>
                                                <span class="line"></span>
                                                <span v-for="(item3, index3) in item1.subUnBalanceNameList"
                                                    style="font-size: 20px;">({{ item3 }})</span>
                                            </span>
                                            <span v-else>{{ item1.sickName }}</span>
                                        </div> -->
                                        <div class="product_name">
                                             {{index1+1}} {{item1.sickName}}——{{ item1.productName }}
                                        </div>
                                        <!-- <div class="product_name_line"></div> -->
                                        <div class="meal_content">
                                            <div class="meal_item_list" v-for="(item2, index2) in item1.effect">
                                                <div class="meal_item_list_round">
                                                   <!-- <img src="https://image.giantgocloud.com/www/ImageMapping/image/20240606/13D5196D44294522A8D00F420E2FE084.png"
                                                        alt="" style="width: 100%;height: 100%;"> -->
                                                </div>
                                                <div class="meal_item_list_text">
                                                    {{ item2.effect }}: {{ item2.ingredient }}
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                                <!-- <div class="details">
                                    <div class="details_title">产品使用详情： </div>
                                    <div class="details_list" v-for="(item1, index1) in item.productDetail">
                                        {{ index1 + 1 }} {{ item1.title }}：{{ item1.view }}
                                    </div>
                                </div> -->
                            </div>
                            <div class="food_right">
                                <img class="wh100"
                                    src="https://image.giantgocloud.com/www/ImageMapping/image/20240929/034C0BCDE53841C7A86B8F254751B03A.png"
                                    alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 第二段end -->
            </template>
            <template v-else-if="item.productsList.length > 8">
                <!-- 第一段 -->
                <div class="pageSize main">

                    <Head text="健康管理方案" bgColor="#C8EBED"></Head>
                    <div style="padding-left: 104px;">
                        <div class="title">
                            <div class="title_left">0{{ productIndex+index }}</div>
                            <div class="title_right">
                                营养建议
                                <div class="line"></div>
                                <!-- <div class="number">{{ index + 1 }}</div> -->
                            </div>
                        </div>
                        <div class="text">
                            {{ objData.singleAnalysisInModel?.productModel.adviceText }}
                        </div>
                        <div class="food">
                            <div class="food_left">
                                <div class="meal">
                                    第
                                    <span v-if="index == 0">一</span>
                                    <span v-if="index == 1">二</span>
                                    <span v-if="index == 2">三</span>
                                    餐:
                                    <div class="line">
                                    </div>
                                </div>
                                <div class="meal_item" v-for="(item1, index1) in item.productsList">
                                    <template v-if="index1 < 4">
                                   <!--     <div class="meal_item_title">
                                            <span v-if="item1.unBalanceName">
                                                <span>{{ item1.unBalanceName }}</span>
                                                <span class="line"></span>
                                                <span v-for="(item3, index3) in item1.subUnBalanceNameList"
                                                    style="font-size: 20px;">({{ item3 }})</span>
                                            </span>
                                            <span v-else>{{ item1.sickName }}</span>
                                        </div> -->
                                        <div class="product_name">
                                             {{index1+1}} {{item1.sickName}}——{{ item1.productName }}
                                        </div>
                                        <!-- <div class="product_name_line"></div> -->
                                        <div class="meal_content">
                                            <div class="meal_item_list" v-for="(item2, index2) in item1.effect">
                                                <div class="meal_item_list_round">
                                               <!--     <img src="https://image.giantgocloud.com/www/ImageMapping/image/20240606/13D5196D44294522A8D00F420E2FE084.png"
                                                        alt="" style="width: 100%;height: 100%;"> -->
                                                </div>
                                                <div class="meal_item_list_text">
                                                    {{ item2.effect }}: {{ item2.ingredient }}
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                                <!-- <div class="details">
                                    <div class="details_title">产品使用详情： </div>
                                    <div class="details_list" v-for="(item1, index1) in item.productDetail">
                                        {{ index1 + 1 }} {{ item1.title }}：{{ item1.view }}
                                    </div>
                                </div> -->
                            </div>
                            <div class="food_right">
                                <img class="wh100"
                                    src="https://image.giantgocloud.com/www/ImageMapping/image/20240929/034C0BCDE53841C7A86B8F254751B03A.png"
                                    alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 第二段 -->
                <div class="pageSize main">

                    <Head text="健康管理方案" bgColor="#C8EBED"></Head>
                    <div style="padding-left: 104px;">
                        <div class="title">
                            <div class="title_left">0{{ productIndex }}</div>
                            <div class="title_right">
                                营养建议
                                <div class="line"></div>
                                <!-- <div class="number">{{ index + 1 }}</div> -->
                            </div>
                        </div>
                        <div class="text">
                            {{ objData.singleAnalysisInModel?.productModel.adviceText }}
                        </div>
                        <div class="food">
                            <div class="food_left">
                                <div class="meal">
                                    第
                                    <span v-if="index == 0">一</span>
                                    <span v-if="index == 1">二</span>
                                    <span v-if="index == 2">三</span>
                                    餐:
                                    <div class="line">
                                    </div>
                                </div>
                                <div class="meal_item" v-for="(item1, index1) in item.productsList">
                                    <template v-if="index1 > 3 && index1 < 8">
                                   <!--     <div class="meal_item_title">
                                            <span v-if="item1.unBalanceName">
                                                <span>{{ item1.unBalanceName }}</span>
                                                <span class="line"></span>
                                                <span v-for="(item3, index3) in item1.subUnBalanceNameList"
                                                    style="font-size: 20px;">({{ item3 }})</span>
                                            </span>
                                            <span v-else>{{ item1.sickName }}</span>
                                        </div> -->
                                        <div class="product_name">
                                             {{index1+1}} {{item1.sickName}}——{{ item1.productName }}
                                        </div>
                                        <!-- <div class="product_name_line"></div> -->
                                        <div class="meal_content">
                                            <div class="meal_item_list" v-for="(item2, index2) in item1.effect">
                                                <div class="meal_item_list_round">
                                             <!--       <img src="https://image.giantgocloud.com/www/ImageMapping/image/20240606/13D5196D44294522A8D00F420E2FE084.png"
                                                        alt="" style="width: 100%;height: 100%;"> -->
                                                </div>
                                                <div class="meal_item_list_text">
                                                    {{ item2.effect }}: {{ item2.ingredient }}
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                                <!-- <div class="details">
                                    <div class="details_title">产品使用详情： </div>
                                    <div class="details_list" v-for="(item1, index1) in item.productDetail">
                                        {{ index1 + 1 }} {{ item1.title }}：{{ item1.view }}
                                    </div>
                                </div> -->
                            </div>
                            <div class="food_right">
                                <img class="wh100"
                                    src="https://image.giantgocloud.com/www/ImageMapping/image/20240929/034C0BCDE53841C7A86B8F254751B03A.png"
                                    alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 第三段start -->
                <div class="pageSize main">

                    <Head text="健康管理方案" bgColor="#C8EBED"></Head>
                    <div style="padding-left: 104px;">
                        <div class="title">
                            <div class="title_left">0{{ productIndex+index }}</div>
                            <div class="title_right">
                                营养建议
                                <div class="line"></div>
                                <!-- <div class="number">{{ index + 1 }}</div> -->
                            </div>
                        </div>
                        <div class="text">
                            {{ objData.singleAnalysisInModel?.productModel.adviceText }}
                        </div>
                        <div class="food">
                            <div class="food_left">
                                <div class="meal">
                                    第
                                    <span v-if="index == 0">一</span>
                                    <span v-if="index == 1">二</span>
                                    <span v-if="index == 2">三</span>
                                    餐:
                                    <div class="line">
                </div>
                                </div>
                                <div class="meal_item" v-for="(item1, index1) in item.productsList">
                                    <template v-if="index1 > 7">
                                    <!--    <div class="meal_item_title">
                                            <span v-if="item1.unBalanceName">
                                                <span>{{ item1.unBalanceName }}</span>
                                                <span class="line"></span>
                                                <span v-for="(item3, index3) in item1.subUnBalanceNameList"
                                                    style="font-size: 20px;">({{ item3 }})</span>
                                            </span>
                                            <span v-else>{{ item1.sickName }}</span>
                                        </div> -->
                                        <div class="product_name">
                                             {{index1+1}} {{item1.sickName}}——{{ item1.productName }}
                                        </div>
                                        <!-- <div class="product_name_line"></div> -->
                                        <div class="meal_content">
                                            <div class="meal_item_list" v-for="(item2, index2) in item1.effect">
                                                <div class="meal_item_list_round">
                                           <!--         <img src="https://image.giantgocloud.com/www/ImageMapping/image/20240606/13D5196D44294522A8D00F420E2FE084.png"
                                                        alt="" style="width: 100%;height: 100%;"> -->
                                                </div>
                                                <div class="meal_item_list_text">
                                                    {{ item2.effect }}: {{ item2.ingredient }}
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                                <!-- <div class="details">
                <div class="details_title">产品使用详情： </div>
                <div class="details_list" v-for="(item1, index1) in item.productDetail">
                    {{ index1 + 1 }} {{ item1.title }}：{{ item1.view }}
                </div>
            </div> -->
                            </div>
                            <div class="food_right">
                                <img class="wh100"
                                    src="https://image.giantgocloud.com/www/ImageMapping/image/20240929/034C0BCDE53841C7A86B8F254751B03A.png"
                                    alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 第三段end -->
            </template>
            <template v-else>
                <div class="pageSize main">

                    <Head text="健康管理方案" bgColor="#C8EBED"></Head>
                    <div style="padding-left: 104px;">
                        <div class="title">
                            <div class="title_left">0{{ productIndex+index }}</div>
                            <div class="title_right">
                                营养建议
                                <div class="line"></div>
                                <!-- <div class="number">{{ index + 1 }}</div> -->
                            </div>
                        </div>
                        <div class="text">
                            {{ objData.singleAnalysisInModel?.productModel.adviceText }}
                        </div>
                        <div class="food">
                            <div class="food_left">
                                <div class="meal">
                                    第
                                    <span v-if="index == 0">一</span>
                                    <span v-if="index == 1">二</span>
                                    <span v-if="index == 2">三</span>
                                    餐:
                                    <div class="line">
                                    </div>
                                </div>
                                <div class="meal_item" v-for="(item1, index1) in item.productsList">
                                 <!--   <div class="meal_item_title">
                                        <span v-if="item1.unBalanceName">
                                            <span>{{ item1.unBalanceName }}</span>
                                            <span class="line"></span>
                                            <span v-for="(item3, index3) in item1.subUnBalanceNameList"
                                                style="font-size: 20px;">({{ item3 }})</span>
                                        </span>
                                        <span v-else>{{ item1.sickName }}</span>
                                    </div> -->
                                    <div class="product_name">
                                         {{index1+1}} {{item1.sickName}}——{{ item1.productName }}
                                    </div>
                                    <!-- <div class="product_name_line"></div> -->
                                    <div class="meal_content">
                                        <div class="meal_item_list" v-for="(item2, index2) in item1.effect">
                                            <div class="meal_item_list_round">
                                <!--                <img src="https://image.giantgocloud.com/www/ImageMapping/image/20240606/13D5196D44294522A8D00F420E2FE084.png"
                                                    alt="" style="width: 100%;height: 100%;"> -->
                                            </div>
                                            <div class="meal_item_list_text">
                                                {{ item2.effect }}: {{ item2.ingredient }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="details">
                                    <div class="details_title">产品使用详情： </div>
                                    <div class="details_list" v-for="(item1, index1) in item.productDetail">
                                        {{ index1 + 1 }} {{ item1.title }}：{{ item1.view }}
                                    </div>
                                </div> -->
                            </div>
                            <div class="food_right">
                                <img class="wh100"
                                    src="https://image.giantgocloud.com/www/ImageMapping/image/20240929/034C0BCDE53841C7A86B8F254751B03A.png"
                                    alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import Head from './head.vue'
import Bottom from './bottom.vue'
export default {
  props: ['objData'],
  components: {
    Head,
    Bottom
  },
  data () {
    return {
      productList: [],
      productIndex: 3
    }
  },
  watch: {
    objData: {
      immediate: true,
      deep: true, // 开启深度监听 专门对付复杂数据类型
      handler: function (newValueA, oldValue) {
        const selfInnovateProduct = newValueA.singleAnalysisInModel.productModel.selfInnovateProduct
        if (!selfInnovateProduct || selfInnovateProduct.length == 0) {
          this.productIndex = 2
        } else {
          const productList = [
            ...newValueA.singleAnalysisInModel.productModel.selfInnovateProduct.masterProductList,
            ...newValueA.singleAnalysisInModel.productModel.selfInnovateProduct.factorProductList,
            ...newValueA.singleAnalysisInModel.productModel.selfInnovateProduct.otherProductList
          ]
          if (productList.length == 0) {
            this.productIndex = 2
          } else {
            this.productIndex = 3
          }
        }
      }
    }
  },
  methods: {
    chunked (arr) {
      return arr.reduce((accumulator, currentValue, currentIndex) => {
        const chunkIndex = Math.floor(currentIndex / 3)
        if (!accumulator[chunkIndex]) {
          accumulator[chunkIndex] = []
        }
        accumulator[chunkIndex].push(currentValue)
        return accumulator
      }, [])
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
    overflow: hidden;

    .title {
        display: flex;

        .title_left {
            font-family: Bahnschrift;
            font-weight: 400;
            font-size: 114px;
            color: #DFE3E8;
            padding-top: 80px;
        }

        .title_right {
            position: relative;
            font-family: Source Han Sans CN;
            font-weight: 500;
            font-size: 42px;
            color: #2baea9;
            padding-top: 100px;

            .line {
                width: 80px;
                height: 2px;
                background-color: #2baea9;
                margin-top: 20px;
            }

            .number {
                position: absolute;
                top: 88px;
                left: 178px;
                width: 31px;
                height: 31px;
                background: #9BD3C8;
                border-radius: 5px;
                font-family: Bahnschrift;
                font-weight: 400;
                font-size: 32px;
                color: #FFFFFF;
                text-align: center;
            }
        }
    }

    .text {
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 20px;
        color: #474F57;
        padding-right: 118px;
    }

    .food {
      margin-top: 90px;
        display: flex;
        justify-content: space-between;

        .food_left {
            .meal {
              display: inline-flex;
                position: relative;
                padding-top: 80px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                font-size: 30px;
                color: #2baea9;

                .line {
                    position: absolute;
                    top: 100px;
                    left: 160px;
                    width: 560px;
                    height: 2px;
                    background-color: #2baea9;
                    &::before{
                      content: '';
                      display: block;
                      width: 10px;
                      height: 10px;
                      background: #2baea9;
                      border-radius: 50%;
                      margin-left: 560px;
                      margin-top: -4px;
                    }
                }

                .line1 {
                    position: absolute;
                    top: 107px;
                    left: 307px;
                    width: 378px;
                    height: 2px;
                    background-color: #2baea9;
                }
            }

            .meal_item {
              margin-top: 20px;
                .meal_item_title {
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 26px;
                    color: #2baea9;
                    padding-top: 30px;

                    .line {
                        display: inline-block;
                        width: 36px;
                        height: 2px;
                        background-color: #2baea9;
                        margin: 0 3px;
                        margin-bottom: 8px;
                    }
                }

                .meal_item_title_list {
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 18px;
                    color: #474F57;
                    padding-left: 22px;
                    // padding-top: 20px;
                }

                .meal_content {
                    // padding-top: 10px;

                    .meal_item_list {
                        display: flex;
                        align-items: center;

                        .meal_item_list_round {
                            flex-shrink: 0;
                            width: 18px;
                            height: 18px;
                            border: 2px solid #2baea9;
                            border-radius: 50%;
                            .img {
                              display: none;
                                width: 100%;
                                height: 100%
                            }
                        }

                        .meal_item_list_text {
                            font-family: Source Han Sans CN;
                            font-weight: 400;
                            font-size: 18px;
                            color: #474F57;
                            padding-left: 14px
                        }
                    }
                }
            }

            .details {
                padding-top: 50px;

                .details_title {
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    font-size: 36px;
                    color: #2baea9;
                    padding-bottom: 20px;
                }

                .details_list {
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 20px;
                    color: #474F57;
                    line-height: 33px;
                }
            }
        }

        .food_right {
            flex-shrink: 0;
            width: 469px;
            height: 834px;
            margin-right: 10px
        }
    }

    .productTitle {
        padding: 30px 0;
        padding-left: 104px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        font-size: 32px;
        color: #2baea9;
    }

    .productContent {
        display: flex;
        justify-content: space-between;
        margin-left: 104px;

        .productContent_left {
            font-family: Source Han Sans CN;
            font-weight: 400;
            font-size: 18px;
            color: #474F57;

            .productContent_left_name {
                font-family: Source Han Sans CN;
                font-weight: 400;
                font-size: 22px;
                color: #474F57;
            }

            .productContent_line {
                margin: 10px 0;
                margin-bottom: 20px;
                width: 600px;
                height: 0.5px;
                border: 0.5px solid #474F57;
                opacity: 0.1;
            }

            .productContent_left_effect {
                display: flex;
                align-items: center;
                padding: 2px 0;

                .effect_img {
                    width: 18px;
                    height: 18px
                }

                .effect_text {
                    padding-left: 10px;
                    font-size: 13px;
                    color: #474F57;
                }
            }

        }

        .productContent_right {
            width: 379px;
            height: 233px;
            flex-shrink: 0;
            margin-right: 60px;

            .img {
                width: 100%;
                height: 100%;
            }
        }
    }
}

.product_name {
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 20px;
    color: #474F57;
    padding: 10px 0;
}

.product_name_line {
    margin: 10px 0;
    margin-bottom: 20px;
    width: 600px;
    height: 0.5px;
    border: 0.5px solid #2baea9;
    opacity: 0.1;
}
</style>
