<!-- proposalReport 全身建议报告 -->
<template>
  <div v-if="dataObj" class="analysisReport">
    <Home :logoPicture="dataObj.logoPicture" :userInfo="dataObj.userInfo"
      :sickName="dataObj.riskAnalysisModel.itemName" />
    <Cover2 imgUrl="https://image.giantgocloud.com/www/ImageMapping/image/20241112/1AA23202FAE546659E743A769D97F543.jpg"/>
    <!-- <Cover3 /> -->
    <Pages2 :userInfo="dataObj.userInfo" :objData="dataObj" />
    <Pages2_1 :userInfo="dataObj.userInfo" :objData="dataObj" />
    <!-- <Cover4 :objData="dataObj"/> -->
    <Tree :objData="dataObj" />
    <Cover2 imgUrl="https://image.giantgocloud.com/www/ImageMapping/image/20241112/CEA5EF051919486F826955DED73540CA.jpg"/>
    <Cover2 imgUrl="https://image.giantgocloud.com/www/ImageMapping/image/20241112/0B19198B287B4D56BB42B7198A1F93F0.jpg"/>
    <Cover2 imgUrl="https://image.giantgocloud.com/www/ImageMapping/image/20241112/BC1FBF028EFD4906AE49D59E356618C9.jpg"/>
    <Cover2 imgUrl="https://image.giantgocloud.com/www/ImageMapping/image/20241112/00BB8B4654E44A0482E4A607DD978715.jpg"/>
    <Cover2 imgUrl="https://image.giantgocloud.com/www/ImageMapping/image/20241112/B9AC4AD8BCA34758B8AD8A04FA7C2225.jpg"/>
    <!-- <template v-if="dataObj.pdfModelList?.length == 1">
      <pages2A :objData="dataObj.pdfModelList[0]" /> >
      <pages2D :objData="dataObj.pdfModelList[0]" />
    </template>
    <template v-if="dataObj.pdfModelList?.length == 2">
      <pages2A :objData="dataObj.pdfModelList[0]" />
      <pages2D :objData="dataObj.pdfModelList[0]" />
      <pages2A :objData="dataObj.pdfModelList[1]" />
      <pages2D :objData="dataObj.pdfModelList[1]" />
    </template>
    <Pages5 :objData="dataObj.singleAnalysisInModel
      .singleAnalysisListForSick[0]" :titleA="dataObj.singleAnalysisInModel
        .singleAnalysisListForSick[0].healthTrouble.projectName + '主题分析'"
      titleB="根据你的上传数据分析，以下是以功能医学疾病机制为科学依据对您关注的肝脏功能健康主题进行分析的结果" />
    <Pages6 :objData="dataObj.singleAnalysisInModel
      .singleAnalysisListForSick[0]" />
    <div v-if="dataObj.singleAnalysisInModel
      .singleAnalysisListForSick.length > 1">
      <Pages5 :objData="dataObj.singleAnalysisInModel
        .singleAnalysisListForSick[1]" :titleA="dataObj.singleAnalysisInModel
        .singleAnalysisListForSick[1].healthTrouble.projectName + '主题分析'"
        titleB="根据你的上传数据分析，以下是以功能医学疾病机制为科学依据对您关注的血脂异常健康主题进行分析的结果" />
      <Pages6 :objData="dataObj.singleAnalysisInModel
        .singleAnalysisListForSick[1]" /> -->
      <!-- <Pages7 :titleA="'二、' + dataObj.singleAnalysisInModel
        .singleAnalysisListForSick[1].healthTrouble.projectName + '生活方式因素解析：'" :objData="dataObj.singleAnalysisInModel
        .singleAnalysisListForSick[1]" /> -->

      <!-- <Pages9 v-if="dataObjA" :objData="dataObjA" :supplementAdvice="dataObj.supplementAdvice" />
      <Pages10 :objData="dataObj.singleAnalysisInModel.liveSuggestMode" /> -->
      <!-- <Pages11 :stageHealthyManageList="dataObj.stageHealthyManageList"
        :objData="dataObj.singleAnalysisInModel.foodList" />
      <template v-if="isShow">
        <nutritionRecomme :objData="dataObj" />
      </template>
      <Nutrition :objData="dataObj" />
      </div> -->
    </div>
</template>

<script>
import H5Report from '@/api/index'
import axios from 'axios'
import Home from './analysisReport/home.vue'
import Pages2 from './analysisReport/pages2.vue'
import Pages2_1 from './analysisReport/pages2_1.vue'
import Pages3 from './analysisReport/pages3.vue'
import Pages4 from './analysisReport/pages4.vue'
import Pages5 from './analysisReport/pages5.vue'
import Pages6 from './analysisReport/pages6.vue'
import Pages7 from './analysisReport/pages7.vue'
// import Pages8 from './analysisReport/pages8.vue'
import Pages9 from './analysisReport/pages9.vue'
import Pages10 from './analysisReport/pages10.vue'
import Pages11 from './analysisReport/pages11.vue'
import Tree from './analysisReport/tree.vue'
import Nutrition from './analysisReport/nutrition.vue'
import Intervene from './analysisReport/intervene.vue'
import Cover from './analysisReport/cover.vue'
import CoverA from './analysisReport/cover1.vue'
import Cover2 from './analysisReport/cover2.vue'
import Cover3 from './analysisReport/cover3.vue'
import Cover4 from './analysisReport/cover4.vue'
import Definition from './analysisReport/definition.vue'
import nutritionRecomme from './analysisReport/nutritionRecomme.vue'
import Etiology from './analysisReport/etiology.vue'
import Risk from './analysisReport/risk.vue'
import Symptom from './analysisReport/symptom.vue'
import Complications from './analysisReport/complications.vue'
import AnalysisFun1 from './analysisReport/analysisFun1.vue'
import AnalysisFun2 from './analysisReport/analysisFun2.vue'
import AnalysisFun3 from './analysisReport/analysisFun3.vue'
import Pages17 from './analysisReport/pages17.vue'
import pages2A from './analysisReport/pages2A.vue'
import pages2B from './analysisReport/pages2B.vue'
import pages2C from './analysisReport/pages2C.vue'
import pages2D from './analysisReport/pages2D.vue'
export default {
  components: {
    Home,
    Cover2,
    Cover3,
    Cover4,
    Pages2,
    Pages2_1,
    pages2A,
    pages2B,
    pages2C,
    pages2D,
    Pages3,
    Pages4,
    Pages5,
    Pages6,
    Pages7,
    // Pages8,
    Pages9,
    Pages10,
    Pages11,
    Tree,
    Nutrition,
    nutritionRecomme,
    Intervene,
    Cover,
    CoverA,
    Definition,
    Etiology,
    Risk,
    Symptom,
    Complications,
    Pages17,
    AnalysisFun1,
    AnalysisFun2,
    AnalysisFun3
  },
  data () {
    return {
      PagesInitData: '',
      dataObj: '',
      dataObjA: '',
      isShow: false,
      treeList: []
    }
  },
  mounted () {
    const id = this.$query('id')
    axios
      .post(
        // `https://healthingcoretest.giantgocloud.com/api/SceneCheckPage/GetPrecisePdfPageData`,
        `${H5Report}/boardApi/CustomScheme/GetProposalReport`, {
          id
        }
      ).then(res => {
        // console.log('8888888', res.data.data)
        this.dataObj = res.data.data
        window.sessionStorage.setItem('userInfo', JSON.stringify(res.data.data.userInfo))
        setTimeout(() => {
          const footerNum = document.getElementsByClassName('footerNum')
          const allPageNum = footerNum.length
          for (let i = 0; i < footerNum.length; i++) {
            // console.log('personaPhysiologyPdfPage[i].innerHTML', personaPhysiologyPdfPage[i].innerHTML)
            footerNum[i].innerHTML = (i + 1) + ' ' + 'of' + ' ' + allPageNum
          }
        }, 500)
        // // 处理产品建议是否显示
        // const productList = [
        //   res.data.data.singleAnalysisInModel.productModel.selfInnovateProduct.masterProductList,
        //   res.data.data.singleAnalysisInModel.productModel.selfInnovateProduct.factorProductList,
        //   res.data.data.singleAnalysisInModel.productModel.selfInnovateProduct.otherProductList
        // ]
        // if (productList.length > 0) {
        //   this.isShow = true
        // } else {
        //   this.isShow = false
        // }
        // // console.log('aa11111', aa)
        // // Head组件数据

        // // 处理矩阵图数据
        // const oldObj = res.data.data
        // const newArr = []
        // const newArr2 = []
        // if (oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList.length == 1) {
        //   for (let i = 0; i < oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList.length; i++) {
        //     if (!oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[i].projectColorCode) {
        //       oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[i].projectColorCode = '#afb1b7'
        //     }
        //   }
        //   if (oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[0].unbalanceFactorList) {
        //     for (let i = 0; i < oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[0].unbalanceFactorList.length; i++) {
        //       const a = oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[0].unbalanceFactorList[i]
        //       a.type = 3
        //       const ifT = true

        //       newArr.push(a)
        //     }
        //   }

        //   oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorListC = []
        //   for (let i = 0; i < newArr.length; i++) {
        //     oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorListC.push(newArr[i])
        //   }
        // }
        // if (oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList.length == 2) {
        //   for (let i = 0; i < oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList.length; i++) {
        //     if (!oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[i].projectColorCode) {
        //       oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[i].projectColorCode = '#afb1b7'
        //     }
        //   }
        //   if (oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[0].unbalanceFactorList) {
        //     for (let i = 0; i < oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[0].unbalanceFactorList.length; i++) {
        //       const a = oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[0].unbalanceFactorList[i]
        //       a.type = 1
        //       const ifT = true
        //       if (oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[1].unbalanceFactorList) {
        //         for (let q = 0; q < oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[1].unbalanceFactorList.length; q++) {
        //           if (oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[1].unbalanceFactorList[q].projectName == a.projectName) {
        //             a.type = 3
        //             break
        //           }
        //         }
        //       }

        //       newArr.push(a)
        //     }
        //   } else {
        //     oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[0].unbalanceFactorList = []
        //   }
        //   if (oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[1].unbalanceFactorList) {
        //     for (let q = 0; q < oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[1].unbalanceFactorList.length; q++) {
        //       const a = oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[1].unbalanceFactorList[q]
        //       a.type = 2
        //       let ifT = true
        //       if (oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[0].unbalanceFactorList) {
        //         for (let i = 0; i < oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[0].unbalanceFactorList.length; i++) {
        //           if (oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[0].unbalanceFactorList[i].projectName == oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[1].unbalanceFactorList[q].projectName) {
        //             ifT = false
        //             break
        //           }
        //         }
        //       }

        //       if (ifT) {
        //         newArr.push(a)
        //       }
        //     }
        //   } else {
        //     oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[1].unbalanceFactorList = []
        //   }

        //   oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorListA = []
        //   oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorListB = []
        //   oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorListC = []
        //   for (let i = 0; i < newArr.length; i++) {
        //     if (newArr[i].type == 1) {
        //       oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorListA.push(newArr[i])
        //     } else if (newArr[i].type == 2) {
        //       oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorListB.push(newArr[i])
        //     } else if (newArr[i].type == 3) {
        //       oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorListC.push(newArr[i])
        //     }
        //   }
        // }

        // // console.log('oldObj.singleAnalysisInModel.matrixGraph', oldObj.singleAnalysisInModel.matrixGraph)
        // // console.log('oldObj.allBodyAnalysisInModel.treeList',oldObj.allBodyAnalysisInModel.treeList)
        // this.dataObjA = oldObj.singleAnalysisInModel.matrixGraph
      })
    // axios
    //   .post(
    //    // `https://healthingcoretest.giantgocloud.com/api/SceneCheckPage/GetPrecisePdfPageData`,
    //    `${H5Report}/api/SceneCheckPage/GetPrecisePdfPageData`,
    //    {
    //      id: '237'
    //    }
    //   ).then(res => {
    //     console.log('8888888')
    //     const data = res.data.data
    //     for (let i = 0; i < data.Plate7.length; i++) {
    //       data.Plate7[i].index2 = i
    //     }
    //     this.PagesInitData = data
    //     console.log(' this.PagesInitData', this.PagesInitData)
    //     // this.HomeData = data.UserInfo
    //     // this.Pages2Data = data.Plate1
    //     // this.Pages6Data = data.Plate3
    //     // let FoodName = data.Plate4.SoupList.map(item => item.Name).join(",")
    //   })
  }
}
</script>

<style lang="scss" scoped>
div {
  box-sizing: border-box;
}

.analysisReport {
  width: 1190px;
}

// .pageSize {
//   position: relative;
//   box-sizing: border-box;
//   width: 1190px;
//   height: 1682px;
// }</style>
