<template>
  <div class="pageSize">

    <Head text="健康管理方案" bgColor="#5aaca9"></Head>
    <div class="analysisReport_box5">
      <div class="analysisReport_box5_info2">
        <div class="analysisReport_box5_info2_title a_flex" style="display: inline-flex;">
          {{ titleA }}
        </div>
        <div class="analysisReport_box5_info2_titleA">
          {{ titleB }}
        </div>
      </div>
      <div class="analysisReport_box5_content">
        <div class="analysisReport_box5_content_info">
          <div class="analysisReport_box5_content_info_title">
            一、目前您的主要健康隐患：
          </div>
          <div class="analysisReport_box5_content_info_ul1">
            <div v-if="objData?.healthTrouble" class="analysisReport_box5_content_info_ul1_li">
              <div v-if="objData.healthTrouble.projectColorCode"
                :style="'border:1px solid '+objData.healthTrouble.projectColorCode"
                class="analysisReport_box5_content_info_ul1_li_text">
                <div style="background:#0a1721;"
                  class="analysisReport_box5_content_info_ul1_li_text_d">
                </div>
                <div
                  class="analysisReport_box5_content_info_ul1_li_textT">
                  {{ objData.healthTrouble.projectName }}
                </div>
              </div>
              <div v-if="objData.healthTrouble.projectLevel"
                :style="`background:${hexToRgb(objData.healthTrouble.projectColorCode, 0.1)};color:${objData.healthTrouble.projectColorCode};border-color:${objData.healthTrouble.projectColorCode}`"
                class="analysisReport_box5_content_info_ul1_li_level a_flex">
                <div>
                  {{ objData.healthTrouble.projectLevel }}
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="analysisReport_box5_content_info" style="margin-top: 55px;">
          <div class="analysisReport_box5_content_info_title">
            二、影响您以上健康隐患的相关失衡因素分析：
          </div>
          <div class="analysisReport_box5_content_info_ul1">
            <div v-if="objData?.factorTroubleList" v-for="(item, index) in objData.factorTroubleList" :key="index"
              class="analysisReport_box5_content_info_ul1_li">
              <div class="analysisReport_box5_content_info_ul1_li_width">
                <div :style="'border:1px solid '+item.projectColorCode"
                  class="analysisReport_box5_content_info_ul1_li_text" >
                  <div  style="background:#0a1721;"
                    class="analysisReport_box5_content_info_ul1_li_text_d">
                  </div>
                  <div  class="analysisReport_box5_content_info_ul1_li_textT">
                    {{ item.projectName }}
                  </div>
                </div>
                <div v-if="item.projectLevel"
                  :style="`background:${hexToRgb(item.projectColorCode, 0.1)};color:${item.projectColorCode};border-color:${item.projectColorCode}`"
                  class="analysisReport_box5_content_info_ul1_li_level a_flex">
                  <div>
                    {{ item.projectLevel }}
                  </div>
                </div>
              </div>
            </div>
            <div v-else style="font-size: 22px;">
              暂无数据
            </div>
          </div>
        </div>
        <div class="analysisReport_box5_content_info" style="margin-top: 55px;">
          <div class="analysisReport_box5_content_info_title">
            三、影响您健康隐患的生活方式因素分析:
          </div>
          <div v-if="objData?.liveTroubleList && objData.liveTroubleList && objData.liveTroubleList.length"
            class="analysisReport_box5_content_info_ul1">
            <div v-for="(item, index) in objData.liveTroubleList" :key="index"
              class="analysisReport_box5_content_info_ul1_li" style="margin-right: 31px;margin-bottom: 27px;">
              <div :style="'border:1px solid '+item.projectColorCode"
                class="analysisReport_box5_content_info_ul1_li_text" style="width: 188px;">
                <div  style="background:#0a1721;"
                  class="analysisReport_box5_content_info_ul1_li_text_d">
                </div>
                <div  class="analysisReport_box5_content_info_ul1_li_textT">
                  {{ item.projectName }}
                </div>
              </div>
              <!-- <div :style="`background:${hexToRgb(item.projectColorCode,0.1)};color:${item.projectColorCode};border-color:${item.projectColorCode}`" class="analysisReport_box5_content_info_ul1_li_level a_flex">
                <div>
                  {{item.projectLevel}}
                </div>
              </div> -->
            </div>
          </div>
          <div v-else class="analysisReport_box5_content_info_ul1" style="align-items: center;">
            <div class="analysisReport_box5_content_info_ul1_img">
              <img class="wh100"
                src="https://image.giantgocloud.com/www/ImageMapping/image/20240517/2E1BB432802746129C6C41AF1E521347.png" />
            </div>
            <div class="analysisReport_box5_content_info_ul1_text">
              生活方式良好，请继续保持～
            </div>
          </div>
        </div>
      </div>
    </div>
    <Bottom />
  </div>
</template>

<script>

import Head from './head.vue'
import Bottom from './bottom.vue'
export default {
  props: ['objData', 'titleA', 'titleB'],
  components: {
    Head,
    Bottom
  },
  methods: {
    hexToRgb (hex, opt) {
      // Remove "#" symbol from the beginning of the string
      hex = hex.replace('#', '')
      // Convert each pair of characters to decimal values for red, green, and blue
      var red = parseInt(hex[0] + hex[1], 16)
      var green = parseInt(hex[2] + hex[3], 16)
      var blue = parseInt(hex[4] + hex[5], 16)
      return 'rgba(' + red + ',' + green + ',' + blue + ',' + opt + ')'
    }
  }
}
</script>

<style lang="scss" scoped>
.analysisReport_box5 {
  width: 974px;
  margin: 0 auto;

  .analysisReport_box5_info2 {
    margin-top: 75px;

    .analysisReport_box5_info2_title {
      padding: 13px 50px 12px 51px;
      background: #2baea9;
      border-radius: 14px;
      font-weight: 500;
      font-size: 36px;
      color: #FFFFFF;
    }

    .analysisReport_box5_info2_titleA {
      margin-top: 48px;
      font-weight: 400;
      font-size: 20px;
      color: #474F57;
    }
  }

  .analysisReport_box5_content {
    margin-top: 64px;

    .analysisReport_box5_content_info {
      .analysisReport_box5_content_info_title {
        font-weight: 400;
        font-size: 34px;
        color: #475059;
      }

      .analysisReport_box5_content_info_ul1 {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        margin-top: 37px;

        .analysisReport_box5_content_info_ul1_img {
          width: 35px;
          height: 31px;
        }

        .analysisReport_box5_content_info_ul1_text {
          margin-left: 16px;
          font-weight: 400;
          font-size: 24px;
          color: #474F57;
        }

        .analysisReport_box5_content_info_ul1_li {
          display: flex;
          align-items: center;
          margin-right: 68px;
          margin-bottom: 31px;

          .analysisReport_box5_content_info_ul1_li_text {
            display: flex;
            align-items: center;
            width: 289px;
            height: 59px;
            // background: #FAE8EA;
            border-radius: 15px;

            .analysisReport_box5_content_info_ul1_li_text_d {
              margin: 0 13px 0 23px;
              width: 7px;
              height: 7px;
              background: #D51931;
              border-radius: 50%;
            }

            .analysisReport_box5_content_info_ul1_li_textT {
              font-weight: 400;
              font-size: 24px;
              color: #0a1721;
            }
          }

          .analysisReport_box5_content_info_ul1_li_level {
            margin-left: 27px;
            width: 43px;
            height: 41px;
            background: #FAE8EA;
            border-radius: 7px;
            border: 1px solid #D51931;
            font-size: 23px;
            color: #D51931;
          }
        }

        .analysisReport_box5_content_info_ul1_li:nth-last-of-type(1) {
          margin-bottom: 0;
        }

        .analysisReport_box5_content_info_ul1_li:nth-last-of-type(2) {
          margin-bottom: 0;
        }
      }
    }
  }
}
.analysisReport_box5_content_info_ul1_li_width{
  display: flex;
  align-items: center;
    width: 389px;
}
</style>
