import { render, staticRenderFns } from "./pages9.vue?vue&type=template&id=5aa72854&scoped=true&"
import script from "./pages9.vue?vue&type=script&lang=js&"
export * from "./pages9.vue?vue&type=script&lang=js&"
import style0 from "./pages9.vue?vue&type=style&index=0&id=5aa72854&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5aa72854",
  null
  
)

export default component.exports